import React from "react";
import { withRouter } from "react-router-dom";
import { Nav, Row } from "react-bootstrap";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="footer flex-lg d-flex">
          <Row className="mt-mb-sm footer-text-align">
            <img
              alt="..."
              style={{ alignSelf: "center" }}
              className="footer-logo"
              src={require("../img/brand/footer-logo.png")}
            />
          </Row>
          <Row className="footer-text-align">
            <Nav.Link href="/brand/home" className="footer-link">
              회사소개
            </Nav.Link>
            <Nav.Link href="/inquiry" className="footer-link">
              제휴/제안
            </Nav.Link>
            <Nav.Link href="/policy" className="footer-link">
              개인정보처리방침
            </Nav.Link>
          </Row>
          <Row className="justify-content-center">
            <div className="footer-notice text-align-center">
              커피나무 홈페이지는 익스플로어9.0 이상, 크롬에 최적화 되어있습니다
            </div>
          </Row>
          <Row className="footer-text-align flex-column">
            <div>
              <div className="footer-text-align footer-column">
                <span className="footer-text pr-md">
                  {"서울시 동작구 상도로 53길 65"}
                </span>
                <span className="footer-text pr-md">
                  {"(주)커피나무 대표자: 백지연  "}
                </span>
                <span className="footer-text">
                  {"개인정보보호책임자: 백승훈"}
                </span>
              </div>
              <div className="footer-text-align footer-column">
                <span className="footer-text pr-md">TEL: 1577-9173</span>
                <span className="footer-text pr-md">FAX: 02-821-0744</span>
                <span className="footer-text pr-md">
                  사업자등록번호: 108-86-14200
                </span>
                <span className="footer-text">
                  E-MAIL: info@coffeenamu.co.kr
                </span>
              </div>
              <div className="footer-text-align footer-column">
                <span className="footer-text">
                  © Coffee namu. All Rights Reserved
                </span>
              </div>
            </div>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Footer);

// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_cognito_identity_pool_id": "ap-northeast-2:cd1e20af-ffda-42de-9ef5-61f4d93758ea",
    "aws_cognito_region": "ap-northeast-2",
    "aws_user_pools_id": "ap-northeast-2_cwlEoERnb",
    "aws_user_pools_web_client_id": "4qlq9o44lv51mv05dfje68ml3r",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://z43elb2hzbbynf37yt5tdvjxoq.appsync-api.ap-northeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "api08a98113",
            "endpoint": "https://6bycyv68qk.execute-api.ap-northeast-2.amazonaws.com/master",
            "region": "ap-northeast-2"
        }
    ]
};


export default awsmobile;

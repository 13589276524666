import { Auth as AWSAuth } from "aws-amplify";

class Auth {
  signUp(username, password, attributes) {
    return AWSAuth.signUp({
      username,
      password,
      attributes,
    });
  }

  signIn(username, password) {
    return AWSAuth.signIn(username, password);
  }

  signOut() {
    return AWSAuth.signOut();
  }

  currentUser() {
    return AWSAuth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });
  }

  changePawssword(user, oldPassword, newPassword) {
    return AWSAuth.changePassword(user, oldPassword, newPassword);
  }

  updateUserAttributes(user, attributes) {
    return AWSAuth.updateUserAttributes(user, attributes);
  }

  userExist(username) {
    return new Promise((resolve, reject) => {
      const code = "000000";
      AWSAuth.confirmSignUp(username, code, {
        // If set to False, the API will throw an AliasExistsException error if the phone number/email used already exists as an alias with a different user
        forceAliasCreation: false,
      })
        .then((data) => console.debug(data))
        .catch((err) => {
          switch (err.code) {
            case "UserNotFoundException":
              resolve(false);
              break;
            case "NotAuthorizedException":
              resolve(true);
              break;
            case "AliasExistsException":
              // Email alias already exists
              resolve(true);
              break;
            case "CodeMismatchException":
              resolve(true);
              break;
            case "ExpiredCodeException":
              resolve(true);
              break;
            default:
              resolve(true);
              break;
          }
        });
    });
  }
}

const instance = new Auth();
export default instance;

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Spinner,
  Nav,
  Col,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import _ from "lodash";
import moment from "moment";
import { FiDownload } from "react-icons/fi";
import cellEditFactory from "react-bootstrap-table2-editor";

import Auth from "../services/Auth";
import { isAdmin } from "../reducers/user";
import { generateId, phoneNumberFormat, downloadCSV } from "../Utils/functions";
import * as reducers from "../reducers";
import {
  initAdmin,
  getAdminConfigs,
  getCustomers,
  updateAdminConfig,
} from "../actions";

const COLUMNS = [
  {
    dataField: "originId",
    text: "고유번호",
    hidden: true,
  },
  {
    dataField: "id",
    text: "아이디",
    hidden: true,
  },
  {
    dataField: "name",
    text: "이름",
    headerAlign: "center",
    headerStyle: { width: "15%" },
    align: "center",
  },
  {
    dataField: "formattedPhone",
    text: "휴대폰 번호",
    headerAlign: "center",
    headerStyle: { width: "20%" },
    align: "center",
  },
  {
    dataField: "email",
    text: "이메일 주소",
    headerAlign: "center",
    headerStyle: { width: "20%" },
    align: "center",
  },
  {
    dataField: "formattedGender",
    text: "성별",
    headerAlign: "center",
    headerStyle: { width: "10%" },
    align: "center",
  },
  {
    dataField: "formattedCreatedAt",
    text: "가입일시",
    headerAlign: "center",
    headerStyle: { width: "15%" },
    align: "center",
  },
  {
    dataField: "address",
    text: "주소",
    hidden: true,
  },
  {
    dataField: "favoriteMenus",
    text: "선호 메뉴",
    headerAlign: "center",
    headerStyle: { width: "10%" },
    align: "center",
  },
  {
    dataField: "favoriteStores",
    text: "선호 지점",
    headerAlign: "center",
    headerStyle: { width: "10%" },
    align: "center",
  },
  {
    dataField: "hanacard",
    text: "하나카드 이벤트",
    hidden: true,
  },
];

const MENU_COLUMN = [
  { dataField: "name", text: "메뉴명", headerAlign: "center", align: "center" },
];
const STORE_COLUMN = [
  { dataField: "name", text: "지점명", headerAlign: "center", align: "center" },
];

const pageListRenderer = ({ pages, onPageChange }) => {
  // just exclude <, <<, >>, >
  const pageWithoutIndication = pages.filter(
    (p) => p.page !== ">>" && p.page !== "<<"
  );
  return (
    <Col style={{ textAlign: "center" }} lg={12} md={12} sm={12} xs={12}>
      {pageWithoutIndication.map((p) => (
        <button
          className={
            p.active
              ? "btn btn-secondary mr-xs"
              : "btn btn-outline-secondary mr-xs"
          }
          onClick={() => onPageChange(p.page)}
        >
          {p.page}
        </button>
      ))}
    </Col>
  );
};

const PageNationOption = {
  pageListRenderer,
  paginationSize: 3,
  prePageText: "<",
  nextPageText: ">",
  hideSizePerPage: true,
};

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      search: null,
      newMenu: null,
      isMenuSaving: false,
      isStoreSaving: false,
      newMenuData: [],
      newStoreData: [],
    };
    this.handleMenuSavePress = this.handleMenuSavePress.bind(this);
    this.handleStoreSavePress = this.handleStoreSavePress.bind(this);
    this.handleCSVDownload = this.handleCSVDownload.bind(this);
  }

  componentDidMount() {
    Auth.currentUser()
      .then((user) => {
        if (isAdmin(user)) {
          this.props.initAdmin();
        } else {
          this.props.history.push("/");
        }
      })
      .catch((err) => {
        this.props.history.push("/");
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching) {
      if (this.props.error) {
        //
      }
      this.setState({
        isMenuSaving: false,
        isStoreSaving: false,
      });
    }
  }

  handleSearchResult = () => {
    let search = this.state.search;
    let customers = this.getColumnFormat();
    let searchedList = [];
    if (search) {
      searchedList = customers.filter(
        (cu) =>
          (cu.name && cu.name.includes(search)) ||
          (cu.phone && cu.phone.includes(search))
      );
    }
    return searchedList;
  };

  getColumnFormat = () => {
    let customers = _.cloneDeep(this.props.customers);
    // let customers = _.cloneDeep(CUSTOMERS)
    for (let customer of customers) {
      if (customer.createdAt) {
        customer.formattedCreatedAt = moment(customer.createdAt).format(
          "YYYY/MM/DD, hh:mm"
        );
      }
      if (customer.gender) {
        customer.formattedGender =
          customer.gender === "male"
            ? "남자"
            : customer.gender === "female"
            ? "여자"
            : "알수없음";
      }
      if (customer.phone) {
        customer.formattedPhone = phoneNumberFormat(customer.phone);
      }
      if (customer.menuIds.length > 0) {
        customer.favoriteMenus = this.buildMenuNames(customer.menuIds);
      }
      if (customer.storeIds.length > 0) {
        customer.favoriteStores = this.buildStoreNames(customer.storeIds);
      }
    }
    return customers;
  };

  buildMenuNames = (ids) => {
    let _menus = this.props.configs.menus.reduce((obj, m) => {
      obj[m.id] = m;
      return obj;
    }, {});
    return (ids || [])
      .map((id) => (id in _menus ? _menus[id].name : ""))
      .join(" ");
  };

  buildStoreNames = (ids) => {
    let _stores = this.props.configs.stores.reduce((obj, s) => {
      obj[s.id] = s;
      return obj;
    }, {});
    return (ids || [])
      .map((id) => (id in _stores ? _stores[id].name : ""))
      .join(" ");
  };

  buildDate = (date) => {
    return moment(date).format("YYYY-MM-DD, hh:mm")
  }

  handleCSVDownload = () => {
    let headers = [
      { key: "originId", text: "고유번호" },
      { key: "email", text: "이메일" },
      { key: "id", text: "아이디" },
      { key: "name", text: "이름" },
      { key: "phone", text: "휴대폰 번호" },
      { key: "gender", text: "성별" },
      { key: "birthdate", text: "생일" },
      { key: "createdAt", text: "가입일시", func:this.buildDate },
      { key: "address", text: "주소" },
      { key: "address2", text: "세부주소" },
      { key: "menuIds", text: "선호 메뉴", func: this.buildMenuNames },
      { key: "storeIds", text: "선호 지점", func: this.buildStoreNames },
      { key: "hanacard", text: "하나카드 이벤트" },
      { key: "marketing", text: "마케팅 동의" },
    ];
    downloadCSV(
      [
        headers.map((h) => h.text),
        ...this.props.customers.map((c) => {
          let _c = [];
          for (let i = 0; i < headers.length; i++) {
            _c = [
              ..._c,
              headers[i].func
                ? headers[i].func(c[headers[i].key])
                : c[headers[i].key],
            ];
          }
          return _c;
        }),
      ],
      "customers"
    );
  };

  handleAddItem = (type) => {
    let tempMenu =
      this.state.newMenuData.length > 0
        ? this.state.newMenuData
        : _.cloneDeep(this.props.configs.menus);
    let tempStore =
      this.state.newStoreData.length > 0
        ? this.state.newStoreData
        : _.cloneDeep(this.props.configs.stores);
    let newMenu = this.state.newMenu;
    let newStore = this.state.newStore;
    if (type === "menu") {
      if (!_.isEmpty(newMenu)) {
        tempMenu[tempMenu.length] = { id: generateId(), name: newMenu };
        this.setState({
          newMenuData: tempMenu,
          newMenu: "",
        });
      }
    }
    if (type === "store") {
      if (!_.isEmpty(newStore)) {
        tempStore[tempStore.length] = {
          id: generateId(),
          name: newStore,
        };
        this.setState({
          newStoreData: tempStore,
          newStore: "",
        });
      }
    }
  };

  handleTempData = (oldValue, newValue, row, column, type) => {
    if (type === "menu") {
      let menus =
        this.state.newMenuData.length > 0
          ? this.state.newMenuData
          : _.cloneDeep(this.props.configs.menus);
      for (let i = 0; i < menus.length; i++) {
        if (menus[i].id === row.id) {
          menus[i].name = newValue;
        }
      }
      this.setState({ newMenuData: menus.filter((menu) => menu.name) });
    }
    if (type === "store") {
      let stores =
        this.state.newStoreData.length > 0
          ? this.state.newStoreData
          : _.cloneDeep(this.props.configs.stores);
      for (let i = 0; i < stores.length; i++) {
        if (stores[i].id === row.id) {
          stores[i].name = newValue;
        }
      }
      this.setState({ newStoreData: stores.filter((store) => store.name) });
    }
  };

  handleMenuPress = (index) => {
    this.setState({ index });
    switch (index) {
      case 1:
        this.props.getCustomers();
        break;
      case 2:
        this.props.getAdminConfigs();
        break;
      default:
        break;
    }
  };

  handleMenuSavePress = () => {
    console.debug(this.state.newMenuData);
    this.setState({ isMenuSaving: true });
    this.props.updateAdminConfig({ menus: this.state.newMenuData });
  };

  handleStoreSavePress = () => {
    console.debug(this.state.newStoreData);
    this.setState({ isStoreSaving: true });
    this.props.updateAdminConfig({ stores: this.state.newStoreData });
  };

  renderBody = () => {
    switch (this.state.index) {
      case 0:
        let customers = this.state.search
          ? this.handleSearchResult()
          : this.getColumnFormat();
        return (
          <div className="flex-column align-items-center pb-lg">
            <div className="d-flex justify-space-between width-100 align-items-center">
              <div className="dashboard-search-box">
                <InputGroup>
                  <FormControl
                    size="lg"
                    onChange={(e) => this.setState({ search: e.target.value })}
                    placeholder="이름, 번호 검색"
                  />
                </InputGroup>
              </div>
              <Button
                onClick={this.handleCSVDownload}
                variant="outline-secondary"
                className="align-self-center"
              >
                <FiDownload />
              </Button>
            </div>
            <div className="width-100">
              <BootstrapTable
                columns={COLUMNS}
                data={customers}
                keyField="originId"
                pagination={paginationFactory(PageNationOption)}
              />
            </div>
          </div>
        );
      case 1:
        let menus =
          this.state.newMenuData.length > 0
            ? this.state.newMenuData
            : _.cloneDeep(this.props.configs.menus);
        let stores =
          this.state.newStoreData.length > 0
            ? this.state.newStoreData
            : _.cloneDeep(this.props.configs.stores);
        return (
          <div className="d-flex pb-lg">
            <Col className="text-align-center" lg={6} md={6} sm={6} xs={6}>
              <InputGroup>
                <FormControl
                  onChange={(e) => this.setState({ newMenu: e.target.value })}
                  size="lg"
                  placeholder="메뉴 추가"
                  // TODO: state의 newMenu 가 갱신안됨
                  value={this.state.newMenu}
                />
                <InputGroup.Append action>
                  <Button
                    className="title-description font-bold"
                    variant="outline-secondary"
                    onClick={() => this.handleAddItem("menu")}
                  >
                    추가
                  </Button>
                </InputGroup.Append>
              </InputGroup>
              <Button
                className="mt-xs width-100"
                disabled={
                  this.state.newMenuData.length === 0 ||
                  this.state.isMenuSaving ||
                  this.state.isStoreSaving
                }
                variant="primary"
                onClick={this.handleMenuSavePress}
              >
                {this.state.isMenuSaving ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "저장하기"
                )}
              </Button>
              <div className="mt-mb-md">
                <div className="body-description">
                  아래 셀 더블 클릭시 수정 가능합니다.
                  <br /> 단, 수정 후 저장을 해 주십시오.
                </div>
              </div>
              <BootstrapTable
                columns={MENU_COLUMN}
                data={menus}
                keyField="id"
                pagination={paginationFactory(PageNationOption)}
                cellEdit={cellEditFactory({
                  mode: "dbclick",
                  blurToSave: true,
                  afterSaveCell: (oldValue, newValue, row, column) => {
                    this.handleTempData(
                      oldValue,
                      newValue,
                      row,
                      column,
                      "menu"
                    );
                  },
                })}
              />
            </Col>
            <Col className="text-align-center" lg={6} md={6} sm={6} xs={6}>
              <InputGroup>
                <FormControl
                  onChange={(e) => this.setState({ newStore: e.target.value })}
                  size="lg"
                  placeholder="지점 추가"
                  // TODO: state의 newMenu 가 갱신안됨
                  value={this.state.newStore}
                />
                <InputGroup.Append action>
                  <Button
                    className="title-description font-bold"
                    variant="outline-secondary"
                    onClick={() => this.handleAddItem("store")}
                  >
                    추가
                  </Button>
                </InputGroup.Append>
              </InputGroup>
              <Button
                className="mt-xs width-100"
                disabled={
                  this.state.newStoreData.length === 0 ||
                  this.state.isMenuSaving ||
                  this.state.isStoreSaving
                }
                variant="primary"
                onClick={this.handleStoreSavePress}
              >
                {this.state.isStoreSaving ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "저장하기"
                )}
              </Button>
              <div className="mt-mb-md">
                <div className="body-description">
                  아래 셀 더블 클릭시 수정 가능합니다.
                  <br /> 단, 수정 후 저장을 해 주십시오.
                </div>
              </div>
              <BootstrapTable
                columns={STORE_COLUMN}
                data={stores}
                keyField="id"
                pagination={paginationFactory(PageNationOption)}
                cellEdit={cellEditFactory({
                  mode: "dbclick",
                  blurToSave: true,
                  afterSaveCell: (oldValue, newValue, row, column) => {
                    this.handleTempData(
                      oldValue,
                      newValue,
                      row,
                      column,
                      "store"
                    );
                  },
                })}
              />
            </Col>
          </div>
        );
    }
  };

  render() {
    return (
      <div>
        <div className="dashboard-navbar">
          <Button variant="none" onClick={() => this.props.history.push("/")}>
            <img src={require("../img/brand/logo_horizontal.png")} />
          </Button>
          <div>
            <Button
              variant="outline-secondary"
              className="mr-sm"
              onClick={() => this.props.history.push("/")}
            >
              웹사이트 가기
            </Button>
          </div>
        </div>
        <div className="mt-lg pl-pr-md">
          <Nav className="mb-lg" variant="tabs" defaultActiveKey="0">
            <Nav.Item>
              <Nav.Link
                onClick={() => this.handleMenuPress(0)}
                className="dashboard-nav-link"
                eventKey="0"
              >
                고객리스트
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                onClick={() => this.handleMenuPress(1)}
                className="dashboard-nav-link"
                eventKey="1"
              >
                메뉴/스토어 수정
              </Nav.Link>
            </Nav.Item>
          </Nav>
          {this.renderBody()}
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      isAdmin: reducers.isAdminGroup(state),
      customers: reducers.getCustomers(state),
      configs: reducers.getAdminConfigs(state),
      isFetching: reducers.isAdminStatusFetching(state),
      error: reducers.getAdminStatusError(state),
    }),
    {
      initAdmin,
      getCustomers,
      getAdminConfigs,
      updateAdminConfig,
    }
  )(Admin)
);

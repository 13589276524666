import { combineReducers } from "redux";
import user, * as fromUser from "./user";
import admin, * as fromAdmin from "./admin";

export default combineReducers({
  user,
  admin,
});

// User
export function getUser(state) {
  return state.user.user;
}

export function getUserAttributes(state) {
  const user = getUser(state);
  return fromAdmin.getUserAttributes(user);
}

export function hasUserAttributes(state) {
  const user = getUser(state);
  return user && user.attributes && user.attributes.name ? true : false;
}

export function isUserStatusFetching(state) {
  return state.user.status.isFetching;
}

export function getUserStatusError(state) {
  return state.user.status.error;
}

export function isAdminGroup(state) {
  return fromUser.isAdmin(getUser(state));
}

// Admin
export function getCustomers(state) {
  return state.admin.customers;
}

export function getAdminConfigs(state) {
  return state.admin.configs;
}

export function getStoreList(state) {
  return state.admin.configs.stores;
}

export function getMenuList(state) {
  return state.admin.configs.menus;
}

export function isAdminStatusFetching(state) {
  return state.admin.status.isFetching;
}

export function getAdminStatusError(state) {
  return state.admin.status.error;
}

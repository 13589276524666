/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const submitInquiry = /* GraphQL */ `
  mutation SubmitInquiry(
    $name: String
    $phone: String
    $email: String
    $message: String
  ) {
    submitInquiry(name: $name, phone: $phone, email: $email, message: $message)
  }
`;
export const setUserPassword = /* GraphQL */ `
  mutation SetUserPassword(
    $userName: String
    $password: String
    $userPoolId: String
  ) {
    setUserPassword(
      userName: $userName
      password: $password
      userPoolId: $userPoolId
    )
  }
`;
export const generateAuthCode = /* GraphQL */ `
  mutation GenerateAuthCode(
    $targetUrl: String!
    $cpTitle: String!
    $ageLimit: Int
  ) {
    generateAuthCode(
      targetUrl: $targetUrl
      cpTitle: $cpTitle
      ageLimit: $ageLimit
    )
  }
`;
export const getAuthByCode = /* GraphQL */ `
  mutation GetAuthByCode($tid: String!) {
    getAuthByCode(tid: $tid)
  }
`;
export const createConfigs = /* GraphQL */ `
  mutation CreateConfigs(
    $input: CreateConfigsInput!
    $condition: ModelConfigsConditionInput
  ) {
    createConfigs(input: $input, condition: $condition) {
      id
      menus {
        id
        name
      }
      stores {
        id
        name
      }
      updatedAt
      createdAt
    }
  }
`;
export const updateConfigs = /* GraphQL */ `
  mutation UpdateConfigs(
    $input: UpdateConfigsInput!
    $condition: ModelConfigsConditionInput
  ) {
    updateConfigs(input: $input, condition: $condition) {
      id
      menus {
        id
        name
      }
      stores {
        id
        name
      }
      updatedAt
      createdAt
    }
  }
`;
export const deleteConfigs = /* GraphQL */ `
  mutation DeleteConfigs(
    $input: DeleteConfigsInput!
    $condition: ModelConfigsConditionInput
  ) {
    deleteConfigs(input: $input, condition: $condition) {
      id
      menus {
        id
        name
      }
      stores {
        id
        name
      }
      updatedAt
      createdAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      originId
      name
      gender
      birthdate
      address
      address2
      email
      phone
      storeIds
      menuIds
      marketing
      hanacard
      isAdmin
      privacy
      homepage
      updatedAt
      createdAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      originId
      name
      gender
      birthdate
      address
      address2
      email
      phone
      storeIds
      menuIds
      marketing
      hanacard
      isAdmin
      privacy
      homepage
      updatedAt
      createdAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      originId
      name
      gender
      birthdate
      address
      address2
      email
      phone
      storeIds
      menuIds
      marketing
      hanacard
      isAdmin
      privacy
      homepage
      updatedAt
      createdAt
    }
  }
`;

import { combineReducers } from "redux";
import * as actions from "../actions";

const initialState = {
  status: {
    isFetching: false,
    error: null,
  },
  customers: [],
  configs: { menus: [], stores: [] },
};

export const getUserAttributes = (user) => {
  return user && user.attributes?user.attributes: {}
  // let attributes = {};
  // if (user && user.attributes) {
  //   attributes.id = user.username;
  //   for (let key of Object.keys(user.attributes)) {
  //     if (key.startsWith("custom")) {
  //       attributes[key.replace("custom:", "")] = JSON.parse(
  //         user.attributes[key]
  //       );
  //     } else {
  //       attributes[key] = user.attributes[key];
  //     }
  //   }
  //   if (!attributes.storeIds) {
  //     attributes.storeIds = [];
  //   }
  //   if (!attributes.menuIds) {
  //     attributes.menuIds = [];
  //   }
  // }
  // return attributes;
};

function status(state = initialState.status, action) {
  switch (action.type) {
    case actions.INIT_ADMIN:
    case actions.GET_CUSTOMERS:
    case actions.GET_ADMIN_CONFIGS:
    case actions.UPDATE_ADMIN_CONFIG:
      return {
        isFetching: true,
        error: null,
      };
    case actions.SET_ADMIN_STATUS_SUCCESS:
      return initialState.status;
    case actions.SET_ADMIN_STATUS_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

const customers = (state = initialState.customers, action) => {
  switch (action.type) {
    case actions.RECEIVE_CUSTOMERS:
      return action.customers;
    default:
      return state;
  }
};

const configs = (state = initialState.configs, action) => {
  switch (action.type) {
    case actions.RECEIVE_ADMIN_CONFIGS:
      return action.configs;
    default:
      return state;
  }
};

export default combineReducers({
  status,
  customers,
  configs,
});

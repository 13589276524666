// User
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const SIGN_UP = "SIGN_UP";
export const UPDATE_USER_ATTRIBUTES = "UPDATE_USER_ATTRIBUTES";
export const GET_USER_ATTRIBUTES = "GET_USER_ATTRIBUTES";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const SET_PASSWORD = "SET_PASSWORD";
export const RECEIVE_USER = "RECEIVE_USER";
export const RECEIVE_USER_ATTRIBUTES = "RECEIVE_USER_ATTRIBUTES";
export const SET_USER_STATUS_SUCCESS = "SET_USER_STATUS_SUCCESS";
export const SET_USER_STATUS_FAILURE = "SET_USER_STATUS_FAILURE";
export const VALIDATE_USER = "VALIDATE_USER";

// Admin
export const INIT_ADMIN = "INIT_ADMIN";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_ADMIN_CONFIGS = "GET_ADMIN_CONFIGS";
export const UPDATE_ADMIN_CONFIG = "UPDATE_ADMIN_CONFIG";
export const RECEIVE_CUSTOMERS = "RECEIVE_CUSTOMERS";
export const RECEIVE_ADMIN_CONFIGS = "RECEIVE_ADMIN_CONFIGS";
export const SET_ADMIN_STATUS_SUCCESS = "SET_ADMIN_STATUS_SUCCESS";
export const SET_ADMIN_STATUS_FAILURE = "SET_ADMIN_STATUS_FAILURE";

// User
export function signIn(username, password) {
  return {
    type: SIGN_IN,
    username,
    password,
  };
}

export function signUp(user) {
  return {
    type: SIGN_UP,
    user,
  };
}

export function signOut() {
  return { type: SIGN_OUT };
}

export function receiveUser(user) {
  return {
    type: RECEIVE_USER,
    user,
  };
}

export function receiveUserAttributes(attributes) {
  return {
    type: RECEIVE_USER_ATTRIBUTES,
    attributes,
  };
}

export function updateUserAttributes(attributes) {
  return {
    type: UPDATE_USER_ATTRIBUTES,
    attributes,
  };
}

export function getUserAttributes() {
  return {
    type: GET_USER_ATTRIBUTES,
  };
}

export function changePassword(oldPassword, newPassword) {
  return {
    type: CHANGE_PASSWORD,
    oldPassword,
    newPassword,
  };
}

export function setPassword(username, password) {
  return {
    type: SET_PASSWORD,
    username,
    password,
  };
}

export function setUserStatusSuccess() {
  return {
    type: SET_USER_STATUS_SUCCESS,
  };
}

export function setUserStatusFailure(error) {
  return {
    type: SET_USER_STATUS_FAILURE,
    error,
  };
}

export function validateUser(tid) {
  return {
    type: VALIDATE_USER,
    tid,
  };
}

// Admin

export function initAdmin() {
  return {
    type: INIT_ADMIN,
  };
}

export function getCustomers() {
  return {
    type: GET_CUSTOMERS,
  };
}

export function getAdminConfigs() {
  return {
    type: GET_ADMIN_CONFIGS,
  };
}

export function updateAdminConfig(config) {
  return {
    type: UPDATE_ADMIN_CONFIG,
    config,
  };
}

export function receiveCustomers(customers) {
  return {
    type: RECEIVE_CUSTOMERS,
    customers,
  };
}

export function receiveAdminConfigs(configs) {
  return {
    type: RECEIVE_ADMIN_CONFIGS,
    configs,
  };
}

export function setAdminStatusSuccess() {
  return {
    type: SET_ADMIN_STATUS_SUCCESS,
  };
}

export function setAdminStatusFailure(error) {
  return {
    type: SET_ADMIN_STATUS_FAILURE,
    error,
  };
}

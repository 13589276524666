import { API as AWSAPI, graphqlOperation } from "aws-amplify";
import { AUTH_TYPE } from "aws-appsync";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import awsconfig from "../aws-exports";

class API {
  createConfigs(configs) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.createConfigs, { input: configs })
      )
        .then((data) => {
          resolve(data.data.createConfigs);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAdminConfigs() {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(graphqlOperation(queries.getConfigs, { id: "admin" }))
        .then((data) => {
          resolve(data.data.getConfigs);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getUsersByPhone(phone) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql({
        ...graphqlOperation(queries.listUsersByPhone, { phone }),
        authMode: AUTH_TYPE.AWS_IAM,
      })
        .then((data) => {
          resolve(data.data.listUsersByPhone.items);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getUsers(limit, nextToken) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(graphqlOperation(queries.listUsers, { nextToken, limit }))
        .then((data) => {
          resolve(data.data.listUsers);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getUser(userId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(graphqlOperation(queries.getUser, { id: userId }))
        .then((data) => {
          resolve(data.data.getUser);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createUser(user) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql({
        ...graphqlOperation(mutations.createUser, {
          input: user,
        }),
        authMode: AUTH_TYPE.AWS_IAM,
      })
        .then((data) => {
          resolve(data.data.createUser);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateUser(user) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.updateUser, {
          input: {
            ...user,
            updatedAt: new Date().toISOString(),
          },
        })
      )
        .then((data) => {
          resolve(data.data.updateUser);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteUser(userId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.deleteUser, {
          input: {
            id: userId,
          },
        })
      )
        .then((data) => {
          resolve(data.data.deleteUser);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateAdminConfigs(configs) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.updateConfigs, { input: configs })
      )
        .then((data) => {
          resolve(data.data.updateConfigs);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  generateAuthCode(targetUrl, cpTitle, ageLimit) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(mutations.generateAuthCode, {
          targetUrl,
          cpTitle,
          ageLimit,
        }),
        authMode: AUTH_TYPE.AWS_IAM,
      })
        .then((data) => {
          resolve(JSON.parse(data.data.generateAuthCode));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAuthByCode(tid) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(mutations.getAuthByCode, {
          tid,
        }),
        authMode: AUTH_TYPE.AWS_IAM,
      })
        .then((data) => {
          resolve(JSON.parse(data.data.getAuthByCode));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  submitInquiry(inquiry) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(mutations.submitInquiry, inquiry),
        authMode: AUTH_TYPE.AWS_IAM,
      })
        .then((data) => {
          resolve(data.data.submitInquiry);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  setUserPassword(userName, password) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(mutations.setUserPassword, {
          userName,
          password,
          userPoolId: awsconfig.aws_user_pools_id,
        }),
        authMode: AUTH_TYPE.AWS_IAM,
      })
        .then((data) => {
          resolve(data.data.setUserPassword);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

const instance = new API();
export default instance;

import { combineReducers } from "redux";
import * as actions from "../actions";
import isEmpty from "lodash/isEmpty";

const initialState = {
  status: {
    isFetching: false,
    error: null,
  },
  user: {},
};

export const isAdmin = (user) => {
  if (
    !isEmpty(user) &&
    !isEmpty(user.signInUserSession) &&
    !isEmpty(user.signInUserSession.accessToken)
  ) {
    const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
    return groups && groups.includes("Admin");
  }
  return false;
};

function status(state = initialState.status, action) {
  switch (action.type) {
    case actions.SIGN_IN:
    case actions.SIGN_UP:
    case actions.SIGN_OUT:
    case actions.CHANGE_PASSWORD:
    case actions.SET_PASSWORD:
    case actions.VALIDATE_USER:
    case actions.GET_USER_ATTRIBUTES:
    case actions.UPDATE_USER_ATTRIBUTES:
      return {
        isFetching: true,
        error: null,
      };
    case actions.SET_USER_STATUS_SUCCESS:
      return initialState.status;
    case actions.SET_USER_STATUS_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

const user = (state = initialState.user, action) => {
  switch (action.type) {
    case actions.RECEIVE_USER:
      return action.user;
    case actions.RECEIVE_USER_ATTRIBUTES:
      return {
        ...state,
        attributes: action.attributes,
      };
    default:
      return state;
  }
};

export default combineReducers({
  status,
  user,
});

import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import { receiveUser } from "../actions";
import Auth from "../services/Auth";

class PrivateRoute extends React.Component {
  componentDidMount() {
    Auth.currentUser()
      .then((user) => {
        this.props.receiveUser(user);
      })
      .catch(() => {
        this.props.receiveUser({});
      });
  }
  render() {
    return <React.Fragment />;
  }
}

export default withRouter(
  connect((state) => ({}), { receiveUser })(PrivateRoute)
);

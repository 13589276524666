import React from "react";
import { isString, isNil } from "lodash";

export const generateId = () => {
  return Math.random()
    .toString(36)
    .substr(2, 9);
};

export const strToHtml = str =>
  str.split("\n").map((line, index) => (
    <span key={index}>
      {line}
      {str.split().length !== index ? <br /> : null}
    </span>
  ));

export const numberValidator = function(string) {
  return string ? string.replace(/[^0-9]+/gi, "") : string;
};

export const isValidId = function(string) {
  var idReg = /^[a-z]+[a-z0-9]{4,19}$/g;
  return idReg.test(string);
};

export const isValidEmail = function(string) {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    string
  );
};

export const phoneNumberFormat = text => {
  if (text) {
    return text
      .replace(/[^0-9]/g, "")
      .replace(
        /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})/,
        "$1-$2-$3"
      )
      .replace("--", "-");
  }
  return text;
};

export const isValidPassword = text => {
  let valid = true;
  if (text) {
    // if (!/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(text)) {
    //   valid = false;
    // }
    // if (!/[^a-z]/.test(text)) {
    //   valid = false;
    // }
    // if (!/[^A-Z]/.test(text)) {
    //   valid = false;
    // }
    // if (!/[^0-9]/.test(text)) {
    //   valid = false;
    // }
    // if (text.length < 8) {
    //   valid = false;
    // }
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*+-_])[A-Za-z\d!@#$%^&*+-_]{8,}$/.test(
        text
      )
    ) {
      valid = false;
    }
    return valid;
  }
};

export const downloadCSV = (rows, fileName = null) => {
  let csvContent = "data:text/csv;charset=utf-8,";

  rows.forEach(function(rowArray) {
    let row = rowArray
      // TODO: r값에 #이 들어갈 경우 뒤에 값이 삭제됨
      .map(r => (!isNil(r) && isString(r) ? `"${r.replace("#", "")}"` : r))
      .join(",");

    csvContent += row + "\r\n";
  });

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(
      new Blob([csvContent], { type: "text/csv;charset=utf-8;" }),
      `${fileName || "export"}.csv`
    );
  } else {
    var encodedUri = encodeURI(csvContent);
    // var encodedUri = csvContent;
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${fileName || "export"}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
  }
};

import React from "react";
import { withRouter, Switch, Route, Redirect } from "react-router";
import { Provider as ReduxProvider } from "react-redux";
import Loadable from "react-loadable";
import Lottie from "react-lottie";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import ReactGA from "react-ga";

import * as pending from "./img/brand/pending.json";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";
import PrivateRoute from "./components/PrivateRoute";
import Admin from "./pages/Admin";
import "./custom.css";
import configureStore from "./store/configureStore";
import rootSaga from "./sagas";

Amplify.configure(awsconfig);
const store = configureStore(window.__INITIAL_STATE__);
store.runSaga(rootSaga);

// Google Analytics

ReactGA.initialize("UA-170611344-1");
ReactGA.pageview(window.location.pathname);

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: pending.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

class Loader extends React.Component {
  componentDidMount() {
    document.getElementById("root").classList.add("height-100");
    document.getElementById("website").classList.add("height-100");
  }

  componentWillUnmount() {
    document.getElementById("root").classList.remove("height-100");
    document.getElementById("website").classList.remove("height-100");
  }

  render() {
    return (
      <div className="loading-back">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            zIndex: 1050,
          }}
        >
          <Lottie width={50} height={50} options={defaultOptions} />
        </div>
      </div>
    );
  }
}

class ScrollToTop extends React.Component {
  state = {
    loading: true,
  };
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return this.props.children;
  }
}

const ScrollToTopWithRouter = withRouter(ScrollToTop);

const Home = Loadable({
  loader: () => import("./pages/Home"),
  loading: Loader,
});
const Redirector = Loadable({
  loader: () => import("./pages/Redirector"),
  loading: Loader,
});
const BrandHome = Loadable({
  loader: () => import("./pages/BrandHome"),
  loading: Loader,
});
const BrandStory = Loadable({
  loader: () => import("./pages/BrandStory"),
  loading: Loader,
});
const BrandHistory = Loadable({
  loader: () => import("./pages/BrandHistory"),
  loading: Loader,
});
const MenuHome = Loadable({
  loader: () => import("./pages/MenuHome"),
  loading: Loader,
});
const MenuOriginal = Loadable({
  loader: () => import("./pages/MenuOriginal"),
  loading: Loader,
});
const MenuPremium = Loadable({
  loader: () => import("./pages/MenuPremium"),
  loading: Loader,
});
const MenuStory = Loadable({
  loader: () => import("./pages/MenuStory"),
  loading: Loader,
});
const Store = Loadable({
  loader: () => import("./pages/Store"),
  loading: Loader,
});
const Franchise = Loadable({
  loader: () => import("./pages/Franchise"),
  loading: Loader,
});
const Inquiry = Loadable({
  loader: () => import("./pages/Inquiry"),
  loading: Loader,
});
const Event = Loadable({
  loader: () => import("./pages/Event"),
  loading: Loader,
});
const SignIn = Loadable({
  loader: () => import("./pages/SignIn"),
  loading: Loader,
});
const SignUp = Loadable({
  loader: () => import("./pages/SignUp"),
  loading: Loader,
});
const FindId = Loadable({
  loader: () => import("./pages/FindId"),
  loading: Loader,
});
const ResetPassword = Loadable({
  loader: () => import("./pages/ResetPassword"),
  loading: Loader,
});
const Policy = Loadable({
  loader: () => import("./pages/Policy"),
  loading: Loader,
});
const MyPage = Loadable({
  loader: () => import("./pages/MyPage"),
  loading: Loader,
});

class Main extends React.Component {
  renderBody = () => (
    <React.Fragment>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/redirect" exact component={Redirector} />
        <Route path="/brand/home" exact component={BrandHome} />
        <Route path="/brand/story" exact component={BrandStory} />
        <Route path="/brand/history" exact component={BrandHistory} />
        <Route path="/menu/home" exact component={MenuHome} />
        <Route path="/menu/original" exact component={MenuOriginal} />
        <Route path="/menu/premium" exact component={MenuPremium} />
        <Route path="/menu/story" exact component={MenuStory} />
        <Route path="/store" exact component={Store} />
        <Route path="/franchise" exact component={Franchise} />
        <Route path="/inquiry" exact component={Inquiry} />
        {/* <Route path="/events" exact component={Event} /> */}
        <Route path="/signin" exact component={SignIn} />
        <Route path="/signup" exact component={SignUp} />
        <Route path="/find-id" exact component={FindId} />
        <Route path="/reset-password" exact component={ResetPassword} />
        <Route path="/policy" exact component={Policy} />
        <Route path="/admin" exact component={Admin} />
        <Route path="/my-page" exact component={MyPage} />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </React.Fragment>
  );

  render() {
    return (
      <ReduxProvider store={store}>
        <PrivateRoute />
        {window.location.pathname.includes("admin") ? (
          <div className="dashboard">
            <Admin />
          </div>
        ) : (
          <div id="website">
            <ScrollToTopWithRouter>
              <NavigationBar />
              {this.renderBody()}
              <Footer />
            </ScrollToTopWithRouter>
          </div>
        )}
      </ReduxProvider>
    );
  }
}

export default withRouter(Main);

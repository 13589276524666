/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getConfigs = /* GraphQL */ `
  query GetConfigs($id: ID!) {
    getConfigs(id: $id) {
      id
      menus {
        id
        name
      }
      stores {
        id
        name
      }
      updatedAt
      createdAt
    }
  }
`;
export const listConfigss = /* GraphQL */ `
  query ListConfigss(
    $filter: ModelConfigsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConfigss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        menus {
          id
          name
        }
        stores {
          id
          name
        }
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      originId
      name
      gender
      birthdate
      address
      address2
      email
      phone
      storeIds
      menuIds
      marketing
      hanacard
      isAdmin
      privacy
      homepage
      updatedAt
      createdAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        originId
        name
        gender
        birthdate
        address
        address2
        email
        phone
        storeIds
        menuIds
        marketing
        hanacard
        isAdmin
        privacy
        homepage
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const listUsersByPhone = /* GraphQL */ `
  query ListUsersByPhone(
    $phone: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByPhone(
      phone: $phone
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        originId
        name
        gender
        birthdate
        address
        address2
        email
        phone
        storeIds
        menuIds
        marketing
        hanacard
        isAdmin
        privacy
        homepage
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;

import {
  // take,
  put,
  call,
  fork,
  select,
  takeEvery,
  all,
} from "redux-saga/effects";
import cloneDeep from "lodash/cloneDeep";

import * as actions from "../actions";
import * as reducers from "../reducers";
import { isAdmin } from "../reducers/user";
import Auth from "../services/Auth";
import API from "../services/API";

const ADMIN_CONFIGS = {
  id: "admin",
  menus: [
    {
      id: "s1x9xg19d",
      name: "에스프레소",
    },
    {
      id: "5evbxzstp",
      name: "아메리카노",
    },
    {
      id: "48gb1l7hc",
      name: "카페라떼",
    },
    {
      id: "plkvlzn4p",
      name: "카푸치노",
    },
    {
      id: "66ytf54mc",
      name: "헤이즐넛 라떼",
    },
    {
      id: "mpiatkucz",
      name: "카라멜 마끼아또",
    },
    {
      id: "1ract6wxr",
      name: "카페모카",
    },
    {
      id: "wdrcolmkz",
      name: "연유 라떼",
    },
    {
      id: "vd5r60hug",
      name: "바닐라 라떼",
    },
    {
      id: "ii420s90k",
      name: "디카페인",
    },
    {
      id: "uvd2ezmtf",
      name: "디카페인 라떼",
    },
    {
      id: "m5ed4v61r",
      name: "콜드브루",
    },
    {
      id: "vwxyddsqx",
      name: "콜드브루 라떼",
    },
    {
      id: "9dwmdie9x",
      name: "흑당커피",
    },
    {
      id: "3pdipcmub",
      name: "녹차 커나치노",
    },
    {
      id: "amzs1q9pk",
      name: "플레인 요거트 커나치노",
    },
    {
      id: "snwwwcw3x",
      name: "딸기 요거트 커나치노",
    },
    {
      id: "418m80zck",
      name: "상큼 망고 스무디",
    },
    {
      id: "epizz4kns",
      name: "스윗 멜론 스무디",
    },
    {
      id: "jg2x4ktqa",
      name: "딸기 복숭아 커나치노",
    },
    {
      id: "ln7wpc4qm",
      name: "오레오 커나치노",
    },
    {
      id: "m1wes15qy",
      name: "자바칩 커나치노",
    },
    {
      id: "dvf93p590",
      name: "레몬 에이드",
    },
    {
      id: "1ryzwdwf0",
      name: "자몽 에이드",
    },
    {
      id: "pppzx991v",
      name: "청포도 모히또 에이드",
    },
    {
      id: "rzhkklau9",
      name: "초콜릿 라떼",
    },
    {
      id: "i4angu50w",
      name: "민트 초콜릿 라떼",
    },
    {
      id: "ucqqlsvju",
      name: "녹차 라떼",
    },
    {
      id: "jg6vv1l1r",
      name: "고구마 라떼",
    },
    {
      id: "nn0sr6ngh",
      name: "얼그레이 밀크티",
    },
    {
      id: "h8n9a9sob",
      name: "복숭아 아이스티",
    },
    {
      id: "265kewvgx",
      name: "페퍼민트티",
    },
    {
      id: "rtnd2r12d",
      name: "캐모마일티",
    },
    {
      id: "zv7kfuygb",
      name: "얼그레이티",
    },
    {
      id: "w0frx6bbf",
      name: "머스캣티",
    },
    {
      id: "g8ubrmuep",
      name: "유자차",
    },
    {
      id: "9jehiyjng",
      name: "매실차",
    },
    {
      id: "e10f1zf6p",
      name: "레몬차",
    },
    {
      id: "fzjhjrffy",
      name: "자몽차",
    },
    {
      id: "x4s8bl212",
      name: "오라봉셔벗",
    },
    {
      id: "3b9luh1g4",
      name: "오라봉 초코 셔벗",
    },
    {
      id: "yk0301dan",
      name: "오라봉 밀크 쉐이크",
    },
    {
      id: "oae9la9km",
      name: "죠리퐁 커나치노",
    },
    {
      id: "r5gf3dzji",
      name: "흑당 바닐라 밀크",
    },
    {
      id: "hm03ybdlo",
      name: "청귤 레몬 캐모마일",
    },
    {
      id: "hj8mg8tt8",
      name: "청귤 유자 얼그레이",
    },
    {
      id: "ld2aqgm9f",
      name: "히비스커스 레몬티",
    },
    {
      id: "kb84oi37t",
      name: "그린애플 페퍼민트",
    },
    {
      id: "9h675kucn",
      name: "흑당 버블 밀크티",
    },
    {
      id: "v1ytkrjkd",
      name: "크림 흑당 버블 밀크티",
    },
    {
      id: "ttfxqo4ed",
      name: "버블 밀크티",
    },
    {
      id: "nz5kmhxg8",
      name: "크림 버블 밀크티",
    },
    {
      id: "ikjr8d8fb",
      name: "생딸기차",
    },
    {
      id: "1w4xfat0p",
      name: "생딸기 우유",
    },
    {
      id: "d9lfa62fr",
      name: "생딸기 눈",
    },
    {
      id: "pk2rhdb8t",
      name: "썸머라떼",
    },
    {
      id: "ed74ihohm",
      name: "에스프레소 쉐이크",
    },
    {
      id: "m5iu81oaw",
      name: "클래식 바닐라 쉐이크",
    },
    {
      id: "0bz1p3rvb",
      name: "달고나 커피",
    },
    {
      id: "wk4gmcm6l",
      name: "달고나 우유",
    },
  ],
  stores: [
    {
      id: "i4xdvqfg4",
      name: "고려대점",
    },
    {
      id: "3saqbu903",
      name: "성신여대역점(보미건설점)",
    },
    {
      id: "dy0d73n5r",
      name: "중앙대후문점",
    },
    {
      id: "qflk4pk7n",
      name: "낙성대역점",
    },
    {
      id: "4rw2nhndh",
      name: "서초반포점",
    },
    {
      id: "ew4egel1y",
      name: "강남역점",
    },
    {
      id: "2344up8id",
      name: "건대입구역점",
    },
    {
      id: "apu90zfig",
      name: "교대점",
    },
    {
      id: "o19rkmg71",
      name: "금천점",
    },
    {
      id: "kpx7ttdup",
      name: "길동점",
    },
    {
      id: "7zpjcqvj7",
      name: "낙성대점",
    },
    {
      id: "r43ih1ia7",
      name: "난곡점",
    },
    {
      id: "xihjdgny4",
      name: "남성역점",
    },
    {
      id: "s77zyiom0",
      name: "노량진3호점",
    },
    {
      id: "l6exs4js1",
      name: "노량진점",
    },
    {
      id: "w577ghpil",
      name: "노량진학원점",
    },
    {
      id: "up9aio8xm",
      name: "노원역점",
    },
    {
      id: "jhiayeltg",
      name: "먹골역점",
    },
    {
      id: "sm76e5s7s",
      name: "명지대점",
    },
    {
      id: "q7ltw51qu",
      name: "방배역점",
    },
    {
      id: "4necjxpic",
      name: "방배카페골목점",
    },
    {
      id: "gbmdxi1kq",
      name: "보라매역점",
    },
    {
      id: "vgk807sf0",
      name: "봉천현대시장점",
    },
    {
      id: "ddzfirafa",
      name: "부천도당점",
    },
    {
      id: "d2tqzrdsw",
      name: "상계역점",
    },
    {
      id: "ku9geb2mi",
      name: "상도2호점",
    },
    {
      id: "98duk9g69",
      name: "상도역점",
    },
    {
      id: "29trrh8l8",
      name: "상도점(상도1호점)",
    },
    {
      id: "eayfrmo1m",
      name: "상록수점",
    },
    {
      id: "fx3c3xvf5",
      name: "서울대입구역점",
    },
    {
      id: "e6d0wbvas",
      name: "숙명여대점",
    },
    {
      id: "jwazk9fo1",
      name: "숭실대점",
    },
    {
      id: "4fdqd5auv",
      name: "신사점",
    },
    {
      id: "wrdrtznrn",
      name: "신상도점",
    },
    {
      id: "iv2hpbtmv",
      name: "약수역점",
    },
    {
      id: "954zqydlv",
      name: "이수점",
    },
    {
      id: "vud6i6l16",
      name: "장승배기점",
    },
    {
      id: "j5gi18x2c",
      name: "청림점",
    },
    {
      id: "206b6fq68",
      name: "필동점",
    },
    {
      id: "xp4z5t0r8",
      name: "함지박사거리점",
    },
    {
      id: "haz5jkcmv",
      name: "서래마을점",
    },
    {
      id: "8sfgo0eir",
      name: "커피나무랩",
    },
    {
      id: "gmuqsvv1r",
      name: "금천구청역점",
    },
    {
      id: "596b0as2z",
      name: "성신여대점",
    },
    {
      id: "m7peppqlz",
      name: "어린이대공원역점",
    },
    {
      id: "077zaug0l",
      name: "이수로스팅하우스",
    },
    {
      id: "65q0n3jkp",
      name: "중앙대정문점",
    },
    {
      id: "ganb6t19g",
      name: "강릉옥천점",
    },
    {
      id: "w2agt1bs8",
      name: "석계역점",
    },
    {
      id: "jmyt0k71l",
      name: "호계사거리점",
    },
    {
      id: "wq92zkoub",
      name: "문정역점",
    },
    {
      id: "22m6x2fd9",
      name: "군자역점",
    },
    {
      id: "gomo9qtdx",
      name: "잠실점",
    },
    {
      id: "ilkgxaalh",
      name: "학동역점",
    },
  ],
};

export function* signIn({ username, password }) {
  try {
    let user = yield call(Auth.signIn, username, password);
    let attributes = yield call(API.getUser, username);

    if (attributes.isAmdin !== isAdmin(user)) {
      attributes = yield call(API.updateUser, {
        ...attributes,
        isAdmin: isAdmin(user),
      });
    }
    yield put(
      actions.receiveUser({
        ...user,
        attributes: attributes,
      })
    );
    yield put(actions.setUserStatusSuccess());
  } catch (error) {
    console.debug("Failed signin", error);
    yield put(actions.setUserStatusFailure(error));
  }
}

export function* watchSignIn() {
  yield takeEvery(actions.SIGN_IN, signIn);
}

export function* signUp({ user }) {
  try {
    const _user = yield call(Auth.signUp, user.id, user.password);
    user.customerId = _user.userSub;
    yield call(API.createUser, {
      id: user.id,
      originId: _user.userSub,
      name: user.name,
      gender: user.gender,
      birthdate: user.birthdate,
      address: user.address,
      address2: user.address2,
      email: user.email,
      phone: user.phone,
      storeIds: user.storeIds || [],
      menuIds: user.menuIds || [],
      hanacard: user.hanacard,
      isAdmin: false,
      marketing: user.marketing,
      privacy: user.privacy,
      homepage: user.homepage,
    });
    yield put(actions.setUserStatusSuccess());
  } catch (error) {
    console.debug("sinup error:", error);
    yield put(actions.setUserStatusFailure(error));
  }
}

export function* watchSignUp() {
  yield takeEvery(actions.SIGN_UP, signUp);
}

export function* validateUser({ tid }) {
  try {
    const userInfo = yield call(API.getAuthByCode, tid);
    // TODO: check phone number
    yield put(actions.setUserStatusSuccess());
  } catch (error) {
    console.debug("sinup error:", error);
    yield put(actions.setUserStatusFailure(error));
  }
}

export function* watchValidateUser() {
  yield takeEvery(actions.VALIDATE_USER, validateUser);
}

export function* signOut() {
  try {
    yield call(Auth.signOut);
    yield put(actions.receiveUser({}));
    yield put(actions.setUserStatusSuccess());
  } catch (error) {
    yield put(actions.setUserStatusFailure(error));
  }
}

export function* watchSignOut() {
  yield takeEvery(actions.SIGN_OUT, signOut);
}

export function* changePassword({ oldPassword, newPassword }) {
  try {
    let user = yield Auth.currentUser();
    yield Auth.changePawssword(user, oldPassword, newPassword);
    yield put(actions.setUserStatusSuccess());
  } catch (error) {
    console.debug(error);
    yield put(actions.setUserStatusFailure(error));
  }
}

export function* watchChangePassword() {
  yield takeEvery(actions.CHANGE_PASSWORD, changePassword);
}

export function* setPassword({ username, password }) {
  try {
    yield API.setUserPassword(username, password);
    yield put(actions.setUserStatusSuccess());
  } catch (error) {
    console.debug(error);
    yield put(actions.setUserStatusFailure(error));
  }
}

export function* watchSetPassword() {
  yield takeEvery(actions.SET_PASSWORD, setPassword);
}

export function* updateUserAttributes({ attributes }) {
  try {
    // console.debug("updateUserAttributes", attributes);
    // let user = yield Auth.currentUser();
    // let _attributes = {};
    // for (let key of Object.keys(attributes)) {
    //   if (CUSTOM_ATTRIBUTES.includes(key)) {
    //     _attributes[`custom:${key}`] = JSON.stringify(attributes[key]);
    //   } else {
    //     _attributes[key] = attributes[key];
    //   }
    // }
    // const data = yield Auth.updateUserAttributes(user, _attributes);
    // // const data = yield Auth.updateUserAttributes(user, {
    // phone_number: "+821065108009",
    // email: "juwan@thegrowing.co",
    // "custom:storeIds": "[1, 2]",
    // "custom:menuIds": "[3,4]",
    // });
    // console.debug(data);
    // let updatedUser = yield Auth.currentUser();
    const oldAttributes = yield select(reducers.getUserAttributes);
    console.debug("a", attributes);
    const updatedAttributes = yield call(API.updateUser, {
      ...cloneDeep(oldAttributes),
      ...attributes,
    });
    console.debug("updatedAttributes", updatedAttributes);
    yield put(actions.receiveUserAttributes(updatedAttributes));
    yield put(actions.setUserStatusSuccess());
  } catch (error) {
    console.debug("Failed to update user", error);
    yield put(actions.setUserStatusFailure(error));
  }
}

export function* watchUpdateUserAttributes() {
  yield takeEvery(actions.UPDATE_USER_ATTRIBUTES, updateUserAttributes);
}

function* _getAllUsers() {
  let users = [];
  let data = {};
  do {
    data = yield call(API.getUsers, 10000, data.nextToken);
    users = [...users, ...data.items];
  } while (data.nextToken);
  return users;
}

function* _getAdminConfigs() {
  let configs = yield call(API.getAdminConfigs);
  if (!configs) {
    configs = yield call(API.createConfigs, ADMIN_CONFIGS);
  }
  return configs;
}

export function* initAdmin() {
  try {
    const configs = yield call(_getAdminConfigs);
    yield put(actions.receiveAdminConfigs(configs));
    const customers = yield call(_getAllUsers);
    yield put(actions.receiveCustomers(customers));
    yield put(actions.setAdminStatusSuccess());
  } catch (error) {
    console.debug("Failed to initAdmin", error);
    yield put(actions.setAdminStatusFailure(error));
  }
}

export function* watchInitAdmin() {
  yield takeEvery(actions.INIT_ADMIN, initAdmin);
}

export function* getAdminConfigs() {
  try {
    const configs = yield call(_getAdminConfigs);
    yield put(actions.receiveAdminConfigs(configs));
    yield put(actions.setAdminStatusSuccess());
  } catch (error) {
    yield put(actions.setAdminStatusFailure(error));
  }
}

export function* watchGetAdminConfigs() {
  yield takeEvery(actions.GET_ADMIN_CONFIGS, getAdminConfigs);
}

export function* updateAdminConfig({ config }) {
  try {
    let configs = yield select(reducers.getAdminConfigs);
    const updatedConfigs = yield call(API.updateAdminConfigs, {
      ...configs,
      ...config,
    });
    yield put(actions.receiveAdminConfigs(updatedConfigs));
    yield put(actions.setAdminStatusSuccess());
  } catch (error) {
    yield put(actions.setAdminStatusFailure(error));
  }
}

export function* watchUpdateAdminConfig() {
  yield takeEvery(actions.UPDATE_ADMIN_CONFIG, updateAdminConfig);
}

export function* getCustomers() {
  try {
    const customers = yield call(_getAllUsers);
    console.debug("customers", customers);
    yield put(actions.receiveCustomers(customers));
    yield put(actions.setAdminStatusSuccess());
  } catch (error) {
    console.debug("Failed to getCustomers", error);
    yield put(actions.setAdminStatusFailure(error));
  }
}

export function* watchGetCustomers() {
  yield takeEvery(actions.GET_CUSTOMERS, getCustomers);
}

export function* getUserAttributes() {
  try {
    const user = yield call(Auth.currentUser);
    const attributes = yield call(API.getUser, user.username);
    const stores = yield select(reducers.getStoreList);
    if (!stores || stores.length === 0) {
      const configs = yield call(API.getAdminConfigs);
      yield put(actions.receiveAdminConfigs(configs));
    }
    yield put(actions.receiveUserAttributes(attributes));
    yield put(actions.setUserStatusSuccess());
  } catch (error) {
    console.debug("Failed to get user attributes", error);
    yield put(actions.setUserStatusFailure(error));
  }
}

export function* watchGetUserAttributes() {
  yield takeEvery(actions.GET_USER_ATTRIBUTES, getUserAttributes);
}

export default function* root() {
  yield all([
    fork(watchSignIn),
    fork(watchSignOut),
    fork(watchGetAdminConfigs),
    fork(watchGetCustomers),
    fork(watchUpdateAdminConfig),
    fork(watchInitAdmin),
    fork(watchUpdateUserAttributes),
    fork(watchSignUp),
    fork(watchChangePassword),
    fork(watchGetUserAttributes),
    fork(watchValidateUser),
    fork(watchSetPassword),
  ]);
}

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Headroom from "react-headroom";
import { Navbar, NavItem, Nav, NavDropdown, Button } from "react-bootstrap";
import { getUser, isAdminGroup } from "../reducers";
import { signOut } from "../actions";

const ToggleView = ({ toggleState, isAdmin, hasUser, onSignOutPress }) =>
  toggleState ? (
    <div className="collapsed-navbar">
      <Nav>
        <NavDropdown title="COFFENAMU">
          <NavDropdown.Item href="/brand/story">BRAND STORY</NavDropdown.Item>
          <NavDropdown.Item href="/brand/history">HISTORY</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="MENU">
          <NavDropdown.Item href="/menu/original">
            COFFEENAMU ORIGINAL
          </NavDropdown.Item>
          <NavDropdown.Item href="/menu/premium">
            COFFEENAMU PREMIUM
          </NavDropdown.Item>
          <NavDropdown.Item href="/menu/story">MENU STORY</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="STORE">
          <NavDropdown.Item href="/store">STORE</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="FRANCHISE">
          <NavDropdown.Item href="/inquiry">CONTACT US</NavDropdown.Item>
          <NavDropdown.Item href="/franchise">PROCESS</NavDropdown.Item>
        </NavDropdown>
        {hasUser ? (
          <React.Fragment>
            <Nav.Link className="normal-sub-title" href="/my-page">
              MY PAGE
            </Nav.Link>
            {isAdmin ? (
              <Nav.Link className="normal-sub-title" href="/admin">
                SETTINGS
              </Nav.Link>
            ) : null}
            <Nav.Link className="normal-sub-title" onClick={onSignOutPress}>
              LOG OUT
            </Nav.Link>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Nav.Link className="normal-sub-title" href="/signin">
              LOG IN
            </Nav.Link>
            <Nav.Link className="normal-sub-title" href="/signup">
              JOIN
            </Nav.Link>
          </React.Fragment>
        )}
        <Nav.Link className="pt-10">
          <Button
            onClick={() =>
              window.open("https://www.instagram.com/coffeenamu_official/")
            }
            className="instagram-button"
          >
            <img
              className="width-100"
              src={require("../img/graphic/instagram.png")}
            />
          </Button>
        </Nav.Link>
      </Nav>
      <hr />
    </div>
  ) : null;

class NavigationBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { barHover: false, toggleMenu: false };
  }

  handleSignOutPress = () => {
    this.props.signOut();
    this.props.history.push("/");
    window.location.reload();
  };

  render() {
    return (
      <Headroom
        style={{
          transition: "all .5s ease-in-out",
        }}
      >
        <Navbar
          onMouseLeave={() => this.setState({ barHover: false })}
          className="nav-bar"
          sticky="top"
          expand="lg"
        >
          <Navbar.Brand className="nav-logo" href="/">
            <img
              alt="..."
              className="nav-image"
              src={require("../img/brand/logo.png")}
            />
          </Navbar.Brand>
          <div
            className={this.state.barHover ? "sub-header on" : "sub-header"}
          />
          <Navbar.Toggle
            onClick={() =>
              this.setState({ toggleMenu: !this.state.toggleMenu })
            }
          />
          <div className="expanded-navbar" id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <NavItem>
                <Nav.Link
                  onMouseEnter={() =>
                    this.setState({ barHover: true, index: 1 })
                  }
                  href="/brand/home"
                  id="custom-nav-color"
                >
                  COFFEENAMU
                </Nav.Link>
                {this.state.barHover && this.state.index === 1 ? (
                  <React.Fragment>
                    <Nav.Link
                      style={{ top: "115px" }}
                      href="/brand/story"
                      id="sub-header-item"
                    >
                      BRAND STORY
                    </Nav.Link>
                    <Nav.Link
                      style={{ top: "155px" }}
                      href="/brand/history"
                      id="sub-header-item"
                    >
                      HISTORY
                    </Nav.Link>
                  </React.Fragment>
                ) : null}
              </NavItem>
              <NavItem>
                <Nav.Link
                  onMouseEnter={() =>
                    this.setState({ barHover: true, index: 2 })
                  }
                  href="/menu/home"
                  id="custom-nav-color"
                >
                  MENU
                </Nav.Link>
                {this.state.barHover && this.state.index === 2 ? (
                  <React.Fragment>
                    <Nav.Link
                      style={{ top: "115px" }}
                      href="/menu/original"
                      id="sub-header-item"
                    >
                      COFEENAMU ORIGINAL
                    </Nav.Link>
                    <Nav.Link
                      style={{ top: "155px" }}
                      href="/menu/premium"
                      id="sub-header-item"
                    >
                      COFEENAMU PREMIUM
                    </Nav.Link>
                    <Nav.Link
                      style={{ top: "195px" }}
                      href="/menu/story"
                      id="sub-header-item"
                    >
                      MENU STORY
                    </Nav.Link>
                  </React.Fragment>
                ) : null}
              </NavItem>
              <NavItem>
                <Nav.Link
                  onMouseEnter={() =>
                    this.setState({ barHover: true, index: 3 })
                  }
                  href="/store"
                  id="custom-nav-color"
                >
                  {"STORE"}
                </Nav.Link>
                {this.state.barHover && this.state.index === 3 ? (
                  <Nav.Link
                    style={{ top: "115px" }}
                    href="/store"
                    id="sub-header-item"
                  >
                    STORE
                  </Nav.Link>
                ) : null}
              </NavItem>
              <NavItem>
                <Nav.Link
                  onMouseEnter={() =>
                    this.setState({ barHover: true, index: 4 })
                  }
                  href="/inquiry"
                  id="custom-nav-color"
                >
                  {"FRANCHISE"}
                </Nav.Link>
                {this.state.barHover && this.state.index === 4 ? (
                  <React.Fragment>
                    <Nav.Link
                      style={{ top: "115px" }}
                      href="/inquiry"
                      id="sub-header-item"
                    >
                      CONTACT US
                    </Nav.Link>
                    <Nav.Link
                      style={{ top: "155px" }}
                      href="/franchise"
                      id="sub-header-item"
                    >
                      PROCESS
                    </Nav.Link>
                  </React.Fragment>
                ) : null}
              </NavItem>
            </Nav>
            <Nav>
              {this.props.user && this.props.user.username ? (
                <React.Fragment>
                  <Nav.Link
                    className="blue-color"
                    href="/my-page"
                    id="login-item"
                  >
                    {"MY PAGE"}
                  </Nav.Link>
                  {this.props.isAdmin ? (
                    <Nav.Link
                      className="blue-color"
                      id="login-item"
                      href="/admin"
                    >
                      {"SETTINGS"}
                    </Nav.Link>
                  ) : null}
                  <Nav.Link
                    className="blue-color"
                    id="login-item"
                    // id="sub-header-item"
                    onClick={this.handleSignOutPress}
                  >
                    {"LOG OUT"}
                  </Nav.Link>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Nav.Link
                    id="login-item"
                    className="blue-color"
                    href="/signin"
                  >
                    {"LOG IN"}
                  </Nav.Link>
                  <Nav.Link
                    id="login-item"
                    className="blue-color"
                    href="/signup"
                  >
                    {"JOIN"}
                  </Nav.Link>
                </React.Fragment>
              )}
              <Nav.Link className="pt-10">
                <Button
                  className="instagram-button"
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/coffeenamu_official/"
                    )
                  }
                >
                  <img
                    className="width-100"
                    src={require("../img/graphic/instagram.png")}
                  />
                </Button>
              </Nav.Link>
            </Nav>
          </div>
          <ToggleView
            toggleState={this.state.toggleMenu}
            hasUser={this.props.user && this.props.user.username}
            isAdmin={this.props.isAdmin}
            onSignOutPress={this.handleSignOutPress}
          />
        </Navbar>
      </Headroom>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      user: getUser(state),
      isAdmin: isAdminGroup(state),
    }),
    {
      signOut,
    }
  )(NavigationBar)
);
